@import './styles/settings/colors';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap&subset=latin-ext');

* {
    box-sizing: border-box;
    outline: none;
   
}

html, body {
    padding: 0;
    margin: 0 auto;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Roboto';
    color: #FFFFFF;
      background-color: rgba(0, 0, 0, 0.657);
}

textarea {
  border:  1px solid $primaryColor;
  background-color: transparent;
  color: #FFFFFF;
  height: 500px;
  max-width: 500px;
  width: 100%;
  padding: 15px;
  resize: none;
  font-size: 18px;

  &:focus {
    outline: none!important;
    box-shadow: inset 0px 0px 10px 0px $primaryColor;

  }

}


ul, li, a {
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5, p {
    padding: 0;
    margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primaryColor;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: green;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  color: inherit; 
}

.sc-gsTCUz.bhdLno {
  position: static !important; 
  transition: .2s ease-in-out;
}

.sc-bdfBwQ {
  // transform: rotate(-130deg);
}

input {
  background-color: transparent;
  border-color: #48ce1f!important;
  transition: .2s ease-in-out;
  color: #FFFFFF;
  margin: 0!important;
  padding: 0;
  width: 100%!important;
  min-width: none!important;

  &:focus {
    outline: none;
    box-shadow: inset 0px 0px 10px 0px #48ce1f;
  }
}
