@import './../../styles/settings/colors';


.random_number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.r_n_title {
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    width: 100%;

    p {
        text-transform: uppercase;
    }
}

.r_n_col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    &:nth-child(2) {
        
        margin-left: 20px;
         p {
            margin-left: 10px;
         }
    }

    p {
        margin-right: 10px;
    }

    input {
        max-width: 200px;
        padding: 8px 5px;
        font-size: 18px;
        text-align: center;
    }
}

.r_n_cols_wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
 
}

.random_result {
    background-color: #48ce1f75;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    animation: start .3s ease-in-out;

    @keyframes start {
        from {
            transform: scale(0);
        }
    }

  }

  .inputs {
    display: flex;
  }