@import './../../styles/settings/colors';


.footer_wrapper {
    background-color: rgba(0, 0, 0, 0.876);
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.socials {
    display: flex;
}

.social_col {
    margin-right: 30px;
    cursor: pointer;
    transition: .2s ease-in;
    overflow: hidden;
    height: 40px;
    svg {
        width: 100%;
        height: 100%;

        path {
            fill: $primaryColor;
        }
    }

    &:last-child {
        margin-right: 0;
    }

}

.date {
    small {
        font-style: italic;
    }
}