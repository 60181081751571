@import './../../styles/settings/colors';


.checkbox_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active {
        .checkbox {
            transition: .4s ease-in-out;
            border-color: black;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $primaryColor;
                transform: scale(.8);
                transition: .3s ease-in-out;
            }
        }
    }
}

.checkbox {
    min-width: 25px;
    min-height: 25px;
    width: 25px;
    height: 25px;
    border: 1px white solid;
    margin-right: 10px;
    position: relative;
 

    &::after {
        content: '';
        transform: scale(0);
    }
}