@import './../../styles/settings/colors';

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #48ce1f;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    font-family: "Lato", Arial, sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    padding: 12px 20px;
    text-align: center;
    margin: 0!important;
    box-shadow: 
      inset 0px -4px 0px rgba(255, 255, 255, 0.3),
      0px 6px 0px rgba(0, 0, 0, 0.25),
      0px 12px 0px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, #5ff442, #2e8c11); /* Gradient */

    &.small {
        padding: 5px 10px;
        &:hover {
            transform: scale(1.01);
        }
    }

    &:hover {
        box-shadow: 
          inset 0px -4px 0px rgba(255, 255, 255, 0.3),
          0px 6px 0px rgba(0, 0, 0, 0.25),
          0px 18px 0px rgba(0, 0, 0, 0.3);
        transform: scale(1.05);
      }
    
      &:active {
        box-shadow: 
          inset 0px 4px 0px rgba(0, 0, 0, 0.3),
          0px 6px 0px rgba(0, 0, 0, 0.25),
          0px 2px 0px rgba(0, 0, 0, 0.2);
        transform: translateY(4px);
      }

    p {
        margin: 0!important;
    }
  }
