@import './../../styles/settings/colors';

.toggle_switch {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.off {
        .active {
            background-color: #828282;
        }

        .switch {
            border-color: #828282;
        }
    }

    &.small {
        .switch {
            max-width: 150px;
        }
    }
}

.switch {
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 2px ;
    border: 2px $primaryColor solid;
    border-radius: 10px;
    overflow: hidden;
}

.option {
    display: flex;
    justify-content: center;
    transition: .2s ease-in-out;
    cursor: pointer;
    padding: 4px;
    z-index: 2;

    &.active_bar {
        color: #FFFFFF!important;
    }

    p {
        font-weight: 700;
    }
}

.active {
    position: absolute;
    height: 100%;
    background-color: $primaryColor;
    transition: .2s ease-in-out;
    border-radius: 0px;
    z-index: 1;
}

.title {
    text-align: center;
    margin-right: 15px;
    h5 {
        font-size: 16px;
    }
}

.message {
    background-color: #d7d7d7;
    padding: 8px;
    border-radius: 12px;
    margin-top: 10px;

    p {
        animation: on .3s ease-in-out;

        @keyframes on {
            from {
                transform: scale(0);
            }
        }
    }
}