@import './../../styles/settings/colors';


.chance {
    max-height: 180px;
    overflow-Y: auto;
    padding-right: 5px;
}

.chance_box {
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-between;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0px;
    }

    p {
        small {
            font-size: 12px;
        }
    }
}

.chance_bar {
    width: 100px;
    border: 1px $primaryColor solid;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    p {
        z-index: 2;
    }
}

.cb_name {
    margin: 0 15px;
    p {
        font-size: 18px;
    }
}

.pr_bar {
    position: absolute;
    left: 0;
    top: 0%;
    background-color: $primaryColor;
    height: 100%;
    z-index: 1;
    transition: .2s ease-in-out;
}
