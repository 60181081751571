@import './../../styles/settings/colors';


.wrapper {
  display: flex;
}


.wheel_container {
  margin-top: 20px;
  min-width: 600px;
  height: 600px;
  position: relative;
}

.wheele_box {
  width: 100%;
  height: 100%;
}

.wheel_of_fortune {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    
    padding: 50px;

  }
  
.button_c {
  justify-content: center;
  align-items: center;
}

.wheel_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.options_text_wrapper {
  display: flex;
  flex-direction: column;
}

.text {
  width: 100%;
  
  textarea {
    font-family: "Roboto";
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.title {
  border:  1px solid $primaryColor;
  border-bottom: 0px;
  padding: 8px 10px;
  border-radius: 0;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  
  p {
    font-size: 20px;
  }
}
.col {
  display: flex;
}

.add_not {
  display: flex;
  margin-bottom: 20px;
}

.button_c {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

.checkbox_container {
  margin-top: 20px;
}

.button_start {
  display: flex;
  justify-content: center;
}

.last_won {
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  animation: show .3s ease-in-out;
  
  @keyframes show {
    from {
      transform: scale(0);
    }
  }
  
  p {
    color: #000000;
  }
}

.last_won_wrapper {
  text-align: center;
  margin-top: 30px;
  height: 78px;
 
  
  p {
    font-size: 20px;
    font-weight: 700;
  }
}

.r_col_wrapper {
  margin-left: 20px;
}

.rand {
  position: absolute;
  left: 40px;
  top: 40px;
}

.random_result {
  background-color: $primaryColor;
  padding: 5px;
  text-align: center;
}

.wheele_box {
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
}

.chance_w_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  pointer-events: none;
}

.left_cols_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_col {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.multiple_box {
  border: 1px solid $primaryColor;
  padding: 10px 10px 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  
}

.range {
  margin: 15px 0 25px 0;
}

.mc_input {
  margin-bottom:  20px;
  input {
    text-align: center;
    height: 40px;
    font-size: 20px;
  }
}

.error {
  text-align: center;
  background-color: gray;
  padding: 7px;
  border-radius: 4px;
  text-align: center;
}


.button_rand {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #48ce1f;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    font-family: "Lato", Arial, sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    padding: 12px 30px;
    text-align: center;
    margin: 0!important;
    box-shadow: 
      inset 0px -4px 0px rgba(255, 255, 255, 0.3),
      0px 6px 0px rgba(0, 0, 0, 0.25),
      0px 12px 0px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, #5ff442, #2e8c11); /* Gradient */
  
    &:hover {
      box-shadow: 
        inset 0px -4px 0px rgba(255, 255, 255, 0.3),
        0px 6px 0px rgba(0, 0, 0, 0.25),
        0px 18px 0px rgba(0, 0, 0, 0.3);
      transform: scale(1.05);
    }
  
    &:active {
      box-shadow: 
        inset 0px 4px 0px rgba(0, 0, 0, 0.3),
        0px 6px 0px rgba(0, 0, 0, 0.25),
        0px 2px 0px rgba(0, 0, 0, 0.2);
      transform: translateY(4px);
    }
  }

}


.last_multiple {
  height: 300px;
  overflow-y: auto;
  padding: 8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid $primaryColor;
}

.last_m {
  text-align: center;
  padding: 5px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  animation: up .3s ease-in-out;

  @keyframes up {
    from {
      transform: scale(0);
    }
  }
}
